/**
 * Followed this example as a workaround
 *
 * - https://codesandbox.io/s/vue-froala-wysiwyg-ke0xb?file=/src/index.js:0-62
 */

import Vue from 'vue'
// import VueFroala from 'vue-froala-wysiwyg'

// Require Froala Editor js file.
// import 'froala-editor/js/froala_editor.pkgd.min.js'

// // Include all plugins
// import 'froala-editor/js/plugins.pkgd.min.js'

// // Require Froala Editor css files.
// import 'froala-editor/css/froala_editor.pkgd.min.css'
// import 'froala-editor/css/froala_style.min.css'

// import FroalaLists from 'froala-editor/js/plugins/lists.min'
// import FroalaAlign from 'froala-editor/js/plugins/align.min'
// import FroalaFontSize from 'froala-editor/js/plugins/font_size.min'

import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/js/plugins.pkgd.min.js'
import VueFroala from './pug-froala'
import PugButton from '@/dashboard/buttons/pug-button'
Vue.use(VueFroala)
Vue.use(PugButton)
