import Vue from 'vue'

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'

import { fas, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faChrome, faFirefoxBrowser, faSafari, faApple, faWindows, faLinux, faAndroid, faUbuntu } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BootstrapVue, {
  BVToastPlugin,
  VBTooltip,
  IconsPlugin,
  SpinnerPlugin,
  OverlayPlugin
} from 'bootstrap-vue'
import Loading from '@/dashboard/loading/loading-spinner'

library.add(faUserSecret)
library.add(faChrome, faFirefoxBrowser, faSafari, faApple, faWindows, faLinux, faAndroid, faUbuntu)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('pug-loading', Loading)
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-tooltip', VBTooltip)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(OverlayPlugin)
Vue.use(SpinnerPlugin)

Vue.use(BVToastPlugin)
// Vue.use(ToastPlugin)
