import { mapGetters, mapMutations, mapActions } from "vuex"
import _ from "lodash"
import { EventBus } from "@/utils/event-bus"

/**
 * @see https://github.com/nuxt/nuxt.js/issues/1593, for how to mount to the root app in nuxt
 * This is for things like loading browser-side cached info, attaching global dom listeners, etc.
 */
export default ({ app }) => {
  if (!app.mixins) {
    app.mixins = []
  }
  app.mixins.push({
    computed: {
      ...mapGetters({
        website: "website/getSelectedWebsite",
        selectedAccount: "accounts/getSelectedAccount",
        user: "user/getUser",
      }),
    },
    data() {
      return {
        timeZone: "",
        totalUnreadNotifications: 0,
        notifications: [],
        hasLoadedNotifications: false,
        initialCount: 0,
        unsubscribe: null,
      }
    },
    computed: {
      totalNotifications() {
        return this.notifications && this.notifications.length ? this.notifications.length : 0
      },
      hasNotifications() {
        if (this.notifications && this.notifications.length) {
          return true
        } else {
          return false
        }
      },
    },
    mounted() {
      this.$nextTick(() => {
        EventBus.$on('user_logged_in', () => {
        })
        EventBus.$on('user_logged_out', () => {
          this.notifications = []
          this.totalUnreadNotifications = 0
          this.hasLoadedNotifications = false
          if (this.unsubscribe) {
            this.unsubscribe()
          }
        })
      })
    },
    watch: {
      user: {
        async handler(newData, oldData) {
          if (oldData == null && newData) {
            // this.initNotifications()
          }
          else if (oldData && newData == null) {
            this.socket.removeAllListeners()
          }
        }
      }
    },
    methods: {
      vueLdCallback() {
      },
      playSound() {
        console.log('playSound')
        const audio = new Audio('https://cdn.storagepug.com/hubfs/5%20Ways%20eBook/Instant%20Message%206.mp3')
        audio.play()
      },
      initNotifications() {
        console.log('notifications.js init')
        _.delay(() => {
          this.unsubscribe = this.$db.collection("notifications").doc(this.user.id).collection('messages').where("is_hidden", "==", false).orderBy("created_at", "asc")
            .onSnapshot((snapshot) => {
              // this.$root.socket.emit('firestore_read', { collection: 'messages' })

              if (snapshot.size == 0) {
                this.hasLoadedNotifications = true
              }
              snapshot.docChanges().forEach((change) => {
                let item = change.doc.data()
                let id = change.doc.id

                if (change.type === "added") {
                  console.log("New notification: ")
                  if (!item.has_read) {
                    this.totalUnreadNotifications++
                  }
                  this.notifications.unshift({ id: id, data: item })
                  if (this.hasLoadedNotifications == true) {
                    // this.playSound()
                  }
                  // this.$db.collection(`notifications/${ this.user.id }/messages`).doc(id).update({
                  //   is_hidden: false
                  // }).then(() => {

                  // }).catch(e => {
                  //   console.log(e)
                  // })
                }
                if (change.type === "modified") {
                  console.log("Modified notification: ")
                  let index = _.findIndex(this.notifications, (o) => { return o.id == id })
                  if (index > -1) {
                    this.notifications[index] = { id: id, data: item }
                    if (!item.has_read) {
                      this.totalUnreadNotifications++
                    }
                  }
                }
                if (change.type === "removed") {
                  console.log("Removed notification: ")
                  let index = _.findIndex(this.notifications, (o) => { return o.id == id })
                  if (index > -1) {
                    this.notifications.splice(index, 1)
                  }
                }
                this.initialCount++
                if (this.initialCount == snapshot.size) {
                  this.hasLoadedNotifications = true
                }
              })
            })

        }, 500)
      }
    }
  })
}
