import Vue from "vue"

import Rollbar from "rollbar"
import { config } from "@/config"
import axios from "axios"
var jsonDiff = require('json-diff')
// import firebase from "firebase/app"
// import * as messaging from "firebase/messaging"
import { Sortable, Swap } from 'sortablejs'
// import Vue from "vue"

Sortable.mount(new Swap())

// import messaging from "firebase/messaging"
// console.log("🚀 ~ file: rollbar.js ~ line 9 ~ messaging", messaging)

var firebaseConfig = {
  apiKey: "AIzaSyATIRMRRVFqfdtNZVZV4G25EwWuy_Wb5AQ",
  authDomain: "app.storagepug.com",
  databaseURL: "https://pug-insights.firebaseio.com",
  projectId: "pug-insights",
  storageBucket: "pug-insights.appspot.com",
  messagingSenderId: "1025455337696",
  appId: "1:1025455337696:web:c3b12f01b79105935a2085"
}

if (process.env.stage != 'production') {
  firebaseConfig = {
    apiKey: "AIzaSyDiFFF1EBKTxFhHBYncI4uhdB8FNKO2mvo",
    authDomain: "pug-insights-staging.firebaseapp.com",
    databaseURL: "https://pug-insights-staging.firebaseio.com",
    projectId: "pug-insights-staging",
    storageBucket: "pug-insights-staging.appspot.com",
    messagingSenderId: "961157393680",
    appId: "1:961157393680:web:376b66342cdd15f10cc995",
  }
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging()
  Vue.messaging = Vue.prototype.$messaging = messaging
}
else {
  Vue.messaging = Vue.prototype.$messaging = null
}
// firebase.firestore.setLogLevel('debug')
const db = firebase.firestore()
// Vue.use(Rollbar, {
//   accessToken: "37c25d1221a9441992e6b57eeb6d3a86",
//   captureUncaught: true,
//   captureUnhandledRejections: true,
//   enabled: true,
//   environment: config.stage,
//   autoInstrument: true,
//   verbose: true,
//   payload: {
//     client: {
//       javascript: {
//         code_version: process.env.codeVersion,
//         source_map_enabled: true,
//         guess_uncaught_frames: true
//       }
//     }
//   }
// })
// export default ({ app, $config }, inject) => {
Vue.firebase = Vue.prototype.$firebase = firebase
Vue.db = Vue.prototype.$db = db
Vue.axios = Vue.prototype.$axios = axios
Vue.jsonDiff = Vue.prototype.$jsonDiff = jsonDiff

Vue.sortable = Vue.prototype.$sortable = Sortable

Vue.rollbar = Vue.prototype.$rollbar = new Rollbar({
  accessToken: "37c25d1221a9441992e6b57eeb6d3a86",
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: config.stage,
  autoInstrument: true,
  verbose: true,
  payload: {
    environment: config.stage,
    client: {
      javascript: {
        code_version: config.code_version,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
  /**
   * Adding Rollbar ignore check for any bot / crawlers that
   * might throw an error.
   *
   * Docs: https://docs.rollbar.com/docs/filter-or-ignore-errors-from-bots#filtering-client-side
   */
  checkIgnore: function (isUncaught, args, payload) {
    // List of known crawlers comes from: https://stackoverflow.com/questions/36588449/how-to-prevent-rollbar-from-reporting-seo-crawlers-activities
    const knownCrawlers = ['Facebot', 'Twitterbot', 'YandexBot', 'bingbot', 'AhrefsBot', 'crawler', 'MJ12bot', 'GoogleBot', 'Mail.RU_Bot', 'SemrushBot', 'YandexMobileBot', 'DotBot', 'SeznamBot', 'Baiduspider']
    if (window.navigator.userAgent) {
      let ignore = false
      for (let crawler of knownCrawlers) {
        if (window.navigator.userAgent.indexOf(crawler) !== -1) {
          ignore = true
          break
        }
      }
      if (config.stage !== 'production') {
        ignore = true
      }

      if (ignore) {
        // ignore known crawler
        return true
      }
    }

    // no other ignores
    return false
  }
})

// }
// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
// Vue.config.errorHandler = (err, vm, info) => {
//   console.log("Vue.config.errorHandler -> vm", err, info)
//   if (vm && vm.$rollbar) {
//     if (err instanceof TypeError) {
//       vm.$rollbar.warning('Warning about TypeError: ', err)
//     } else if (err instanceof SyntaxError) {
//       vm.$rollbar.warning('Warning about SyntaxError: ', err)
//     } else {
//       vm.$rollbar.error(err)
//     }
//     throw err // rethrow for the browser
//   }
// }
