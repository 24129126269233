import { mapGetters, mapMutations, mapActions } from "vuex"
import * as uuid from "uuid/v1"
import _ from "lodash"
import { EventBus } from "@/utils/event-bus"

/**
 * @see https://github.com/nuxt/nuxt.js/issues/1593, for how to mount to the root app in nuxt
 * This is for things like loading browser-side cached info, attaching global dom listeners, etc.
 */
export default ({ app }) => {
  if (!app.mixins) {
    app.mixins = []
  }
  app.mixins.push({
    computed: {
      ...mapGetters({
        website: "website/getSelectedWebsite",
        user: "user/getUser",
      }),
    },
    data() {
      return {
        socket: null,
        hidden: null,
        mouse: {
          click: false,
          move: false,
          pos: { x: 0, y: 0 },
          pos_prev: false
        },
        body: null,
        width: null,
        height: null,
        defaultColors: ["#e15759", "#8647b3", "#4e79a7", "#f28e2c", "#76b7b2", "#59a14f", "#edc949", "#af7aa1", "#ff9da7", "#9c755f", "#bab0ab", "#4e79a7", "#f28e2c", "#e15759", "#76b7b2", "#59a14f", "#edc949", "#af7aa1", "#ff9da7", "#9c755f", "#bab0ab", "#4e79a7", "#f28e2c", "#e15759", "#76b7b2", "#59a14f", "#edc949", "#af7aa1", "#ff9da7", "#9c755f", "#bab0ab", "#4e79a7", "#f28e2c", "#e15759", "#76b7b2", "#59a14f", "#edc949", "#af7aa1", "#ff9da7", "#9c755f", "#bab0ab"],
        numPointers: 0,
        numEvents: 0,
        isBrowserFocused: false,
        pointerUserHash: {},
        pointerUserHashRemove: {},
        pointerPageHash: {},
        mouseAppId: null,
        mouseExpireTime: 15000
      }
    },
    created() {
      this.mouseAppId = uuid()
    },
    mounted() {
      this.$nextTick(() => {
        this.socket = io("https://ws.storagepug.app")
        this.$router.afterEach((to, from) => {
          if (this.user) {
            setTimeout(() => {
              let ev = { user_id: this.user.id, user_name: this.user.name, mouseAppId: this.mouseAppId }
              this.socket.emit('mouse_leave', ev)
            }, 100)
            if (Object.keys(this.pointerUserHash).length > 0) {
              for (let id in this.pointerUserHash) {
                let svg = $(`#${ id }`).remove()
              }
              this.pointerUserHash = {}
            }
          }
        })
        EventBus.$on('user_logged_in', () => {
          // this.initMouse()
        })
        EventBus.$on('user_logged_out', () => {
          // this.socket.removeAllListeners()
        })
        this.initMouse()
      })
    },
    beforeDestroy() {
      EventBus.$off('user_logged_in')
    },
    // watch: {
    //   user: {
    //     handler(newData, oldData) {
    //       if (oldData == null && newData) {
    //         this.initMouse()
    //       }
    //       else if (oldData && newData == null) {
    //         this.socket.removeAllListeners()
    //       }
    //     }
    //   }
    // },
    methods: {
      initMouse() {
        _.delay(() => {

          this.socket.on("server_cache_started", (data) => {
            if (data.cache_job && this.user && data.cache_job['user_id'] == this.user.id) {
              EventBus.$emit('cache_status', data)
            }
          })
          this.socket.on("mouse_move", (data) => {
            if (this.user && this.user.is_super_admin == true) {
              if (this.$config.stage == 'production') {
                this.processMouseEvent(data)
              }
            }
            this.numEvents++
          })

          this.socket.on("mouse_leave", (data) => {
            this.numEvents++
            // if (this.user.is_super_admin == true) {
            if (this.pointerUserHash[data.mouseAppId]) {
              let svg = $(`#${ data.mouseAppId }`).remove()
              // let svg = document.getElementById(data.user_id).remove()
              delete this.pointerUserHash[data.mouseAppId]

              if (this.pointerUserHashRemove[data.mouseAppId]) {
                delete this.pointerUserHashRemove[data.mouseAppId]
              }
              this.numPointers--
            }
            else {
            }
            // }
          })

          this.socket.on('dashboard_page_saved', (data) => {
            if (this.user && data && data.path == this.$route.path && data.mouseAppId != this.mouseAppId) {
              if (this.user.is_super_admin) {
                this.toastErrorAlt(`This page was just saved by ${ data.user_name }. It might have outdated data. Refresh the page for the most current version.`, true)
              }
              else {
                this.toastErrorAlt("This page was just saved by someone else. It might have outdated data. Refresh the page for the most current version.", true)
              }
            }
          })

          this.socket.on("website_redeploy_finished", (data) => {
            if (this.user && data && data.website_id && this.website.id == data.website_id) {
              this.$store.commit("website/HAS_NO_WEBSITE_CHANGES")
              this.$store.commit("website/REDEPLOY_FINISHED")
              EventBus.$emit('website_redeploy_finished')
              try {
                this.sendHotjarEvent({
                  action: "Dashboard Redeploy Finished",
                })
              } catch (e) {
                console.error("🚀 ~ file: LeadsHome.vue ~ line 190 ~ mounted ~ e", e)
              }
            }
          })
          this.socket.on("website_redeploy_failed", (data) => {
            if (this.user && data && data.website_id && this.website.id == data.website_id) {
              // this.$store.commit("website/HAS_NO_WEBSITE_CHANGES")
              this.$store.commit("website/REDEPLOY_FINISHED")
              EventBus.$emit('website_redeploy_failed')
              try {
                this.sendHotjarEvent({
                  action: "Dashboard Redeploy Failed",
                })
              } catch (e) {
                console.error("🚀 ~ file: LeadsHome.vue ~ line 190 ~ mounted ~ e", e)
              }
            }
          })
          this.socket.on("website_redeploy_started", (data) => {
            if (this.user && data && data.website_id && this.website.id == data.website_id) {
              this.$store.commit("website/REDEPLOY_STARTED")
            }
          })

          this.body = document.getElementById('pug-body')
          this.width = window.innerWidth
          this.height = window.innerHeight

          if (this.user && this.user.is_super_admin == true) {
            this.body.onmousemove = this.mouseMove
          }
          else {
            this.body.onmousemove = this.mouseMoveSlow
          }
          // if (/*@cc_on!@*/false) { // check for Internet Explorer
          //   document.onfocusin = this.onFocus
          //   document.onfocusout = this.onBlur
          // } else {
          //   // window.onfocus = this.onFocus
          //   // window.onblur = this.onBlur
          // }
          var visibilityChange
          if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            this.hidden = "hidden"
            visibilityChange = "visibilitychange"
          } else if (typeof document.msHidden !== "undefined") {
            this.hidden = "msHidden"
            visibilityChange = "msvisibilitychange"
          } else if (typeof document.webkitHidden !== "undefined") {
            this.hidden = "webkitHidden"
            visibilityChange = "webkitvisibilitychange"
          }

          // document.onvisibilitychange = this.onchange
          if (typeof document.addEventListener === "undefined" || this.hidden === undefined) {
            console.error("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.")
          } else {
            // Handle page visibility change
            document.addEventListener(visibilityChange, this.onchange, false)
          }
        }, 500)

        window.addEventListener("beforeunload", () => {
          if (this.user) {
            let ev = { user_id: this.user.id, user_name: this.user.name, mouseAppId: this.mouseAppId }
            this.socket.emit('mouse_leave', ev)
          }
        })
      },
      onchange(e) {
        if (document[this.hidden] && this.user) {
          this.isBrowserFocused = false
          setTimeout(() => {
            let ev = { user_id: this.user.id, user_name: this.user.name, mouseAppId: this.mouseAppId }
            this.socket.emit('mouse_leave', ev)
          }, 100)
        }
        else {
          this.isBrowserFocused = true
        }
      },
      removeMouse(mouseAppId) {
        let svg = $(`#${ mouseAppId }`).remove()
        if (this.pointerUserHash[mouseAppId]) {
          delete this.pointerUserHash[mouseAppId]
        }
        if (this.pointerUserHashRemove[mouseAppId]) {
          delete this.pointerUserHashRemove[mouseAppId]
        }
        this.numPointers--
      },
      processMouseEvent(data) {
        if (data.mouseAppId != this.mouseAppId && data.path == this.$route.path && !this.pointerUserHash[data.mouseAppId]) {
          // * user is on same page add them
          this.numPointers++
          let svg = this.createSvg(data.mouseAppId, this.defaultColors[this.numPointers], data.user_name)
          this.body.appendChild(svg)

          this.pointerUserHash[data.mouseAppId] = svg
          this.updateSvgPosition(data.mouseAppId, data.pos)
        }
        else if (data.mouseAppId != this.mouseAppId && data.path == this.$route.path && this.pointerUserHash[data.mouseAppId]) {
          this.updateSvgPosition(data.mouseAppId, data.pos)
        }
        else if (data.path != this.$route.path && this.pointerUserHash[data.mouseAppId]) {
          this.removeMouse(data.mouseAppId)
        }
      },
      updateSvgPosition(mouseAppId, pos) {
        $(`#${ mouseAppId }`).css({
          position: 'absolute',
          left: `${ pos.x * this.width / pos.width }px`,
          top: `${ pos.y }px`
        })
        if (this.pointerUserHashRemove[mouseAppId]) {
          clearTimeout(this.pointerUserHashRemove[mouseAppId])
        }
        this.pointerUserHashRemove[mouseAppId] = setTimeout(() => {
          this.removeMouse(mouseAppId)
        }, this.mouseExpireTime)
      },
      createSvg(user_id, color, user_name) {
        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
        svg.setAttribute("xmlns", "http://www.w3.org/2000/svg")
        svg.setAttribute("width", "24")
        svg.setAttribute("height", "24")
        svg.setAttribute("viewBox", "0 0 24 24")
        svg.setAttribute("fill", "none")
        svg.setAttribute("stroke", "currentColor")
        svg.setAttribute("stroke-width", "2")
        svg.setAttribute("stroke-linecap", "round")
        svg.setAttribute("stroke-linejoin", "round")
        svg.classList.add("feather")
        svg.classList.add("feather-mouse-pointer")
        let path = document.createElementNS("http://www.w3.org/2000/svg", "path")
        path.setAttribute("d", "M3 3l7.07 16.97 2.51-7.39 7.39-2.51L3 3z")
        let path2 = document.createElementNS("http://www.w3.org/2000/svg", "path")
        path2.setAttribute("d", "M13 13l6 6")
        svg.appendChild(path)
        svg.appendChild(path2)
        let div = document.createElement('div')
        let div2 = document.createElement('div')
        div.setAttribute('id', user_id)
        div.classList.add("user-pointer")
        div.classList.add("d-flex")
        div.classList.add("flex-column")
        div.classList.add("justify-content-start")
        div.classList.add("align-items-start")
        div2.classList.add("small")
        div2.classList.add("mt-1")
        div.style.cssText = `font-size:12px;position:absolute;z-index:999999999;color:${ color };`
        div2.innerHTML += user_name
        div.appendChild(svg)
        div.appendChild(div2)
        return div
      },
      mouseMove: _.debounce(function (e) {
        if (this.user) {
          this.mouse.pos.x = e.clientX // (e.clientX / this.width) * 100
          this.mouse.pos.width = this.width
          this.mouse.pos.y = e.clientY
          this.mouse.pos.height = this.height
          this.mouse.move = true
          let ev = { pos: this.mouse.pos, pos_prev: this.mouse.pos_prev, path: this.$route.path, user_id: this.user.id, user_name: this.user.name, mouseAppId: this.mouseAppId }
          this.socket.emit('mouse_move', ev)
        }
      }, 50, { 'maxWait': 100 }),
      mouseMoveSlow: _.debounce(function (e) {
        if (this.user) {
          this.mouse.pos.x = e.clientX // (e.clientX / this.width) * 100
          this.mouse.pos.width = this.width
          this.mouse.pos.y = e.clientY
          this.mouse.pos.height = this.height
          this.mouse.move = true
          let ev = { pos: this.mouse.pos, pos_prev: this.mouse.pos_prev, path: this.$route.path, user_id: this.user.id, user_name: this.user.name, mouseAppId: this.mouseAppId }
          this.socket.emit('mouse_move', ev)
        }
      }, 50, { 'maxWait': 100 }),

    }
  })
}
