import Vue from 'vue'
import VueKindergarten from 'vue-kindergarten'
import child from '~/kindergarten/child'
import BaseRoutePerimeterMixin from '@/mixins/base-route-perimeter'

Vue.use(VueKindergarten, {
  child
})

Vue.mixin(BaseRoutePerimeterMixin)
