import Vue from 'vue'
import VSkeletonLoader from '@/helpers/loaders/skeleton'

// const opts = {
//   theme: { disable: true }
// }

// if (process.client) {
// const VSkeletonLoader = require('vuetify/lib/components/VSkeletonLoader')

// Vue.use(Vuetify, {
//   components: { VSkeletonLoader }
// })
Vue.component('v-skeleton-loader', VSkeletonLoader)
// }
// Vue.use(Vuetify, {
//   theme: {
//     disable: true
//   },
//   components: {
//     VLazy
//   }
// })

// export default (ctx) => {
//   const vuetify = new Vuetify({
//     theme: {
//       disable: true
//     },
//     components: {
//       VLazy
//     }
//   })

//   ctx.app.vuetify = vuetify
//   ctx.$vuetify = vuetify.framework
// }
