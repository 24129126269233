import { mapGetters, mapMutations, mapActions } from "vuex"
import { generateApiUrl } from "@/utils/helpers"
import axios from "axios"
import _ from "lodash"
import { PubSub } from "aws-amplify"
import { EventBus } from "@/utils/event-bus"

/**
 * @see https://github.com/nuxt/nuxt.js/issues/1593, for how to mount to the root app in nuxt
 * This is for things like loading browser-side cached info, attaching global dom listeners, etc.
 */
export default ({ app }) => {
  if (!app.mixins) {
    app.mixins = []
  }
  app.mixins.push({
    computed: {
      ...mapGetters({
        website: "website/getSelectedWebsite",
        user: "user/getUser",
        selectedAccount: "accounts/getSelectedAccount",
        currentFcmToken: "user/getCurrentFcmToken",
      }),
    },
    data() {
      return {
        sub: null,
        sub2: null,
        sub3: null,
        messaging: null
      }
    },
    mounted() {
      this.$nextTick(() => {
        EventBus.$on('user_logged_in', () => {
          this.initGlobal()
        })
        if (this.$messaging) {
          this.$messaging.onMessage((payload) => {
            console.log('Message received. ', payload)
            // let tmp = {
            //   title: payload.data.title,
            //   noAutoHide: true,
            //   variant: 'white',
            //   toaster: 'b-toaster-bottom-center'
            // }
            // if (payload.data.url) {
            //   tmp.href = payload.data.url
            // }
            this.toastSuccess(payload.data.title)
          })
        }
      })
    },
    beforeDestroy() {
      EventBus.$off('user_logged_in')
    },
    watch: {
      user: {
        handler(newData, oldData) {
          if (oldData == null && newData) {
            console.log('global.js init handler')
            this.initGlobal()
          }
        }
      }
    },
    methods: {
      initGlobal() {
        console.log('global.js init')

        if (!this.currentFcmToken && this.user && this.$messaging) {

          let vapidKey = 'BEaC0OBK5bX3K9wcAlEAKLH4swPnvoXSRlNTJ_M4GQIBbU20bD9RCO8pX1aFJsM4Rnaab3LDChTWpeOogTL4R4M'
          if (this.$config.stage != 'production') {
            vapidKey = 'BBOjcfVOhFjw6dDSgQHJOW_whvqYq40182KikcSZfTybywqbUJZX-wju4ODQCG1Vfed_C6U5ijQh1ahhFD6xMHk'
          }
          this.$messaging.getToken({ vapidKey: vapidKey }).then((currentToken) => {
            if (currentToken) {
              this.$store.dispatch('user/setCurrentFcmToken', currentToken)
              const url = generateApiUrl(`user/${ this.user.id }/fcm?token=${ currentToken }`)

              // this is a GET for caching purposes
              axios.get(url)
                .then()
                .catch(e => console.warn("error caught saving fcm_token", e))

            } else {
              console.log('No registration token available. Request permission to generate one.')
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err)
          })
        }
      },
      setWebsiteChanges(changeText, diff = null, type = 'update') {
        return new Promise(async (resolve, reject) => {
          // console.log(this.$route, this.website, changeText)
          // if (this.website.domain && this.website.netlify_site_id) {
          let url = generateApiUrl(`${ this.website.account_id }/website/${ this.website.id }/has-changes`)
          try {
            let body = {
              created_at: new Date(),
              text: changeText,
              user_name: this.user.name,
              user_email: this.user.email,
              type: type,
              user_id: this.user.id,
              account_id: this.website.account_id,
              website_name: this.selectedAccount.name,
              url: `https://app.storagepug.com${ this.$route.path }`
            }
            if (diff) {
              body.diff = diff
            }

            try {
              this.sendHotjarEvent({
                user_name: this.user.name,
                user_email: this.user.email,
                action: "Dashboard Updates",
              })
            } catch (e) {
              console.log("🚀 ~ file: LeadsHome.vue ~ line 190 ~ mounted ~ e", e)
            }
            // this.playSound()
            setTimeout(() => {
              this.$store.commit("website/HAS_WEBSITE_CHANGES")
            }, 800)
            this.$root.socket.emit('dashboard_page_saved', { path: this.$route.path, user_name: this.user.name, user_id: this.user.id, mouseAppId: this.$root.mouseAppId })
            let dbPath = `changelog`
            if (type == 'unit_cache') {
              dbPath = `changelog/${ this.website.account_id }/events`
            }

            return this.$db.collection(dbPath).add(body).then((docRef) => {
              // console.log("🚀 ~ file: global.js ~ line 123 ~ this.$db.collection ~ data", data)
              return axios.post(url, body).then(() => {
                return resolve(docRef.id)
              }).catch((e) => {
                console.log(e)
              })
            }).catch((e) => {
              console.log(e)
            })


          } catch (e) {
            console.log("🚀 ~ file: global.js ~ line 164 ~ returnnewPromise ~ e", e)

          }
          // }
        })
      },
      setNoWebsiteChanges() {
        return new Promise(async (resolve, reject) => {
          // if (this.website.domain && this.website.netlify_site_id) {
          let url = generateApiUrl(`${ this.website.account_id }/website/${ this.website.id }/has-no-changes`)
          try {
            await axios.get(url, { headers: { 'x-purge': true } })
            this.$store.commit("website/HAS_NO_WEBSITE_CHANGES")
          } catch (e) {
            console.log("🚀 ~ file: global.js ~ line 96 ~ returnnewPromise ~ e", e)

          }
          // }
        })
      },
      setRedeployStarted(webUrl) {
        return new Promise(async (resolve, reject) => {
          // if (this.website.domain && this.website.netlify_site_id) {
          try {
            let url = generateApiUrl(`${ this.website.account_id }/website/${ this.website.id }/redeploy-started`)
            // let body = {
            //   text: 'Website Redeploy: User Initiated',
            //   user_id: this.user.id,
            //   url: webUrl
            // }
            let body = {
              created_at: new Date(),
              text: 'Website Redeploy: User Initiated',
              user_name: this.user.name,
              user_email: this.user.email,
              user_id: this.user.id,
              url: webUrl,
              account_id: this.website.account_id,
              website_name: this.selectedAccount.name,
            }
            try {
              this.sendHotjarEvent({
                ...body,
                action: "Dashboard Redeploy Started",
              })
            } catch (e) {
              console.log("🚀 ~ file: LeadsHome.vue ~ line 190 ~ mounted ~ e", e)
            }
            this.$db.collection(`changelog`).add(body).then(() => {

            }).catch((e) => {
              console.log(e)
            })
            axios.post(url, body).then(() => {

            }).catch((e) => {
              console.log(e)
            })

            setTimeout(() => {
              this.$store.commit("website/REDEPLOY_STARTED")
            }, 200)
          } catch (e) {

          }
          // }
        })
      },
      playSoundAffect() {
        // const audio = new Audio('https://cdn.storagepug.com/hubfs/5%20Ways%20eBook/Instant%20Message%206.mp3')
        // audio.play()
      }
    }
  })
}
