import Vue from 'vue'
import percentage from '@/dashboard/filters/percentage'
import snakeToTitlecase from '@/dashboard/filters/snake-to-titlecase'
import titlecase from '@/dashboard/filters/titlecase'
import currency from '@/dashboard/filters/currency'
import truncate from '@/dashboard/filters/truncate'
import phone from '@/dashboard/filters/phone'

Vue.filter('truncate', truncate)
Vue.filter('titlecase', titlecase)
Vue.filter('snakeToTitlecase', snakeToTitlecase)
Vue.filter('currency', currency)
Vue.filter('percentage', percentage)
Vue.filter('phone', phone)
