import Vue from 'vue'
import "@aws-amplify/ui-vue"
import Amplify, { PubSub } from "aws-amplify"
import { AWSIoTProvider } from '@aws-amplify/pubsub'
// import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers'
// import { AmplifyPlugin, Authenticator, SignIn, SignUp, SignOut, ConfirmSignUp, ConfirmSignIn, ForgotPassword, SetMfa } from 'aws-amplify-vue'
import awsconfig from '@/aws-exports'
import ToastMixin from '@/mixins/toast'

if (process.client) {
  // Amplify.configure(awsconfig)



  // AmplifyModules.PubSub.subscribe('myTopic').subscribe({
  //   next: (data) => console.log('Message received', data.value),
  //   error: (error) => console.error(error),
  //   close: () => console.log('Done')
  // })
  // Vue.use(AmplifyPlugin, AmplifyModules)
  Amplify.configure(awsconfig)
  try {
    Amplify.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_region: 'us-east-1',
        aws_pubsub_endpoint: 'wss://a2w7n3u33zwrzz-ats.iot.us-east-1.amazonaws.com/mqtt'
      })
    )
    // AmplifyModules.PubSub.configure({ protocol: 'wss' })
  } catch (e) {
    console.error('Disconnected from websocket.', e)
  }
  // AmplifyModules.Auth.currentCredentials().then((info) => {
  //   const cognitoIdentityId = info.data.IdentityId
  //   console.log(cognitoIdentityId)
  // })
  // Vue.component('Authenticator', Authenticator)
  // Vue.component('SignIn', SignIn)
  // Vue.component('SignUp', SignUp)
  // Vue.component('SignOut', SignOut)
  // Vue.component('ConfirmSignUp', ConfirmSignUp)
  // Vue.component('ConfirmSignIn', ConfirmSignIn)
  // Vue.component('ForgotPassword', ForgotPassword)
  // Vue.component('SetMfa', SetMfa)

  Vue.mixin(ToastMixin)
}
