import { mapGetters, mapMutations, mapActions } from "vuex"
import _ from "lodash"
import { EventBus } from "@/utils/event-bus"
import * as LDClient from "launchdarkly-js-client-sdk"


/**
 * @see https://github.com/nuxt/nuxt.js/issues/1593, for how to mount to the root app in nuxt
 * This is for things like loading browser-side cached info, attaching global dom listeners, etc.
 */
export default ({ app }) => {
  if (!app.mixins) {
    app.mixins = []
  }
  app.mixins.push({
    computed: {
      ...mapGetters({
        selectedAccount: "accounts/getSelectedAccount",
        user: "user/getUser",
      }),
    },
    data() {
      return {
        ld: null,
        ld_users: null,
        useFirebaseUnits: false
      }
    },

    watch: {
      selectedAccount: {
        async handler(newData, oldData) {
          if (newData && Object.keys(newData).length > 0 && this.selectedAccount && this.selectedAccount.id && this.selectedAccount.name) {
            var account = {
              "key": this.selectedAccount.id,
              "name": this.selectedAccount.name,
            }
            if (!this.ld && account.name) {
              // Darkly Accounts
              this.ld = LDClient.initialize(this.$config.launch_darkly_accounts_key, account)
              this.ld.on('ready', () => {
                this.useFirebaseUnits = this.ld.variation("firebase-units", false)
                this.ld.on('change:firebase-units', (value, previous) => {
                  this.useFirebaseUnits = value
                })
              })
            }
            else {
              if (this.selectedAccount && this.selectedAccount.id != null && this.selectedAccount.name && account.name) {
                await this.ld.identify(account)
                this.useFirebaseUnits = this.ld.variation("firebase-units", false)
              }
            }
          }
        },
        deep: true
      },
      user: {
        async handler(newData, oldData) {
          if (oldData == null && newData) {
            var user = {
              "key": this.user.id,
              "name": this.user.name,
              custom: {
                is_super_admin: this.user.is_super_admin
              }
            }
            if (!this.ld_users) {
              // Darkly Users
              this.ld_users = LDClient.initialize(this.$config.launch_darkly_users_key, user)
              this.ld_users.on('ready', () => {


              })
            }
            else {
              if (this.selectedAccount && this.selectedAccount.id != null) {
                await this.ld_users.identify(user)
              }
            }
          }
        }
      }
    }
  })
}
