import Vue from 'vue'
import {
  configure,
  extend,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { parse, format, isValid, isEqual, isBefore, isAfter } from 'date-fns'
import { isCreditCard, isURL, isDecimal, matches, isMobilePhone, contains } from 'validator'
import * as _ from 'lodash'
// const validator = await import("validator" /* webpackChunkName: 'validator' */)

// Install all default rules
Object.keys(rules).forEach((rule) => {
  // eslint-disable-next-line import/namespace
  extend(rule, rules[rule])
})

extend("phone_number", {
  validate: (val) => isMobilePhone(val),
  message: "Please enter a valid phone number."
})

extend("name", {
  validate: (val) => contains(val, 'name'),
  message: "Please enter a name."
})

extend("figmaUrl", {
  validate: (val) => contains(val, 'figmaUrl'),
  message: "Please include a figma link."
})

extend("text_location", {
  validate: (val) => !contains(val, 'location'),
  message: "The route cannot contain the word location or locations."
})

// install the 'required' rule.
// extend('required', {
//   ...required,
//   message: 'This field is required'
// })

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
})

// Extend VeeValidate rules
function toDate(value) {
  // handle timestamps
  if (typeof value === 'number') {
    return new Date(value)
  }

  // Handle date values
  if (value instanceof Date) {
    return value
  }

  // handle strings, use any date parsing library
  // like: dayjs, date-fns, moment
  return parse(value)
}

function isEmailValid(email) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

extend('credit_card', {
  validate: (val) => isCreditCard(val),
  message: 'Please enter a valid credit card.'
})

extend('url', {
  validate: (val) => {
    return isURL(val)
  },
  message: 'Please enter a valid url.'
})

extend('kebab_case', {
  validate: (val) => {
    return matches(val, '^([a-z][a-z0-9]*)(-[a-z0-9]+)*$')
  },
  message: 'Please convert input to kebab-case, i.e. storage-pug.'
})

extend('firebase_topic_key', {
  validate: (val) => {
    return matches(val, '^[a-zA-Z0-9-_.~%]+$')
  },
  message: 'Invalid Firebase topic key, valid characters: [a-zA-Z0-9-_.~%]. For more info, see https://firebase.google.com/docs/cloud-messaging/send-message#send-messages-to-topics-legacy'
})

extend('decimal', {
  validate: (val) => {
    const forceStringVal = String(val)
    return isDecimal(forceStringVal)
  },
  message: 'Please enter a valid decimal.'
})

extend('date_after', {
  validate: function isAfter(value, { compare }) {
    return value.getTime() > compare.getTime()
  },
  castValue: toDate, // casts the value
  params: [
    {
      name: 'compare', // don't forget to provide a name
      cast: toDate // casts the param value
    }
  ],
  message: 'The provided date must be after {compare}'
})

extend('date_before', {
  validate: function isBefore(value, { compare }) {
    return value.getTime() < compare.getTime()
  },
  castValue: toDate, // casts the value
  params: [
    {
      name: 'compare', // don't forget to provide a name
      cast: toDate // casts the param value
    }
  ],
  message: 'The provided date must be before {compare}'
})

extend('date_format', {
  validate: function dateFormat(value, { dateFormat }) {
    if (typeof value !== 'string') {
      return isValid(value) ? value : false
    }

    const parsed = parse(value, dateFormat, new Date())

    // if date is not valid or the formatted output after parsing does not match
    // the string value passed in (avoids overflows)
    if (!isValid(parsed) || format(parsed, dateFormat) !== value) {
      return false
    }

    return true
  },
  params: ['dateFormat'],
  message: 'The provided date must be formatted as {dateFormat}.'
})

/**
 * The date_between function is inherently inclusive, meaning
 * it will check for dates that are between and equal to the
 * min and max date values
 */
extend('date_between', {
  validate: function dateBetween(value, { minDate, maxDate, dateFormat }) {
    if (!minDate || !maxDate) {
      return false
    }

    let minVal = minDate
    let maxVal = maxDate
    let dateVal = value

    if (dateFormat) {
      minVal = parse(minDate, dateFormat, new Date())
      maxVal = parse(maxDate, dateFormat, new Date())
      dateVal = parse(value, dateFormat, new Date())
    }

    return (
      isEqual(dateVal, maxVal) ||
      isEqual(dateVal, minVal) ||
      (isBefore(dateVal, maxVal) && isAfter(dateVal, minVal))
    )
  },
  params: ['minDate', 'maxDate', 'dateFormat', 'inclusive'],
  message: 'The provided date must be between {minDate} and {maxDate}.'
})

extend('comma_separated_emails', {
  validate: function checkEmails(value) {
    const emailsArray = _.map(_.split(value, ","), _.trim)
    const badEmails = []
    for (let email of emailsArray) {
      if (!isEmailValid(email)) {
        badEmails.push(email)
      }
    }

    if (badEmails.length === 0) {
      return true
    }
    else {
      return `Invalid email(s): ${ badEmails.join(", ") }`
    }
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
