/**
 * Determine which breakpoint the client browser is at
 */
export default ({ app }) => {
  if (!app.mixins) {
    app.mixins = []
  }
  app.mixins.push({
    data() {
      return {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        currentWidth: 0,
        currentBreakpoint: null,
        isExtraSmall: false,
        isSmall: false,
        isSmallAndBelow: false,
        isMedium: false,
        isMediumAndBelow: false,
        isLarge: false,
        isLargeAndBelow: false,
        isSmallAndAbove: false,
        isMediumAndAbove: false,
        isLargeAndAbove: false,
        isExtraLarge: false,
        isExtraLargeAndAbove: false,
      }
    },
    mounted() {
      if (process.client) {
        // Event listener to update breakpoint sizes
        window.addEventListener("resize", this.handleResize)
        this.handleResize()
      }
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.handleResize)
    },
    methods: {
      handleResize() {
        const clientWidth = window.innerWidth
        this.currentWidth = clientWidth

        // Set the current breakpoint based on current screen width
        if (clientWidth < this.sm) {
          this.currentBreakpoint = "extra-small"
        } else if (clientWidth < this.md) {
          this.currentBreakpoint = "small"
        } else if (clientWidth < this.lg) {
          this.currentBreakpoint = "medium"
        } else if (clientWidth < this.xl) {
          this.currentBreakpoint = "large"
        } else if (clientWidth >= this.xl) {
          this.currentBreakpoint = "extra-large"
        }

        // Update $breakpoint's booleans
        this.isExtraSmall = this.currentBreakpoint === "extra-small"
        this.isSmall = this.currentBreakpoint === "small"
        this.isSmallAndBelow = this.currentBreakpoint === "extra-small" || this.currentBreakpoint === "small"
        this.isMedium = this.currentBreakpoint === "medium"
        this.isMediumAndBelow = this.currentBreakpoint === "extra-small" || this.currentBreakpoint === "small" || this.currentBreakpoint === "medium"
        this.isLarge = this.currentBreakpoint === "large"
        this.isLargeAndBelow = this.currentBreakpoint === "extra-small" || this.currentBreakpoint === "small" || this.currentBreakpoint === "medium" || this.currentBreakpoint === "large"
        this.isSmallAndAbove = this.currentBreakpoint === "small" || this.currentBreakpoint === "medium" || this.currentBreakpoint === "large" || this.currentBreakpoint === "extra-large"
        this.isMediumAndAbove = this.currentBreakpoint === "medium" || this.currentBreakpoint === "large" || this.currentBreakpoint === "extra-large"
        this.isLargeAndAbove = this.currentBreakpoint === "large" || this.currentBreakpoint === "extra-large"
        this.isExtraLarge = this.currentBreakpoint === "extra-large"
        this.isExtraLargeAndAbove = this.currentBreakpoint === "extra-large"
      }
    }
  })
}
