import Vue from "vue"
// import { config } from "@/config"

export default ({ app, $config }) => {
  /*
   ** Only run on client-side and only in production mode
   */
  if ($config.stage !== "production") {
    return
  }

  try {
    /**
     * Include Snowplow analytics
     */
    ; (function (p, l, o, w, i, n, g) {
      if (!p[i]) {
        console.log("i", i, o)
        p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || []
        p.GlobalSnowplowNamespace.push(i)
        p[i] = function () {
          ; (p[i].q = p[i].q || []).push(arguments)
        }
        p[i].q = p[i].q || []
        n = l.createElement(o)
        g = l.getElementsByTagName(o)[0]
        n.async = 1
        n.src = w
        g.parentNode.insertBefore(n, g)
      }
    })(window, document, "script", "https://d35d2bh9d4dm20.cloudfront.net/pug.js", "pug")

    // Initialise a tracker
    window.pug("newTracker", "pug_insights", "collector.storagepug.app", {
      appId: "pug_insights",
      postPath: "/com.storagepug/track",
      cookieName: "_pug_",
      forceSecureTracker: true,
      platform: "web",
      contexts: {
        webPage: true,
        performanceTiming: true,
      },
    })

    // Set user from cookie
    window.pug("setUserIdFromCookie", "pugg_uid")
    window.pug("enableLinkClickTracking", null, true, true)
    window.pug("enableFormTracking")
    window.pug("enableActivityTracking", 30, 20)

    const contact = Vue.$storage.get("user")

    /*
     ** Every time the route changes (fired on initialization too)
     **
     */
    app.router.afterEach((to, from) => {
      // Set custom and referrer URLs
      // console.log(to)
      window.pug("setCustomUrl", to.path)
      window.pug("setReferrerUrl", from.path)
      // Send to snowplow
      if (window.Intercom) {
        window.Intercom("update")
      }
      if (contact) {
        var eventData = {
          email: contact.email,
          name: contact.name,
          enabled: contact.enabled ? 1 : 0,
          email_verified: contact.email_verified ? 1 : 0,
          is_super_admin: contact.is_super_admin ? 1 : 0,
          has_logged_in: contact.has_logged_in ? 1 : 0,
          has_been_sent_welcome_email: contact.has_been_sent_welcome_email ? 1 : 0,
          status: contact.status,
          phone: contact.phone,
          job_title: contact.job_title,
          selected_account_id: contact.selected_account_id,
        }
        window.pug("trackPageView", null, [
          {
            schema: "iglu:com.pug/pug_insights_user/jsonschema/1-0-0",
            data: eventData,
          },
        ])
      } else {
        window.pug("trackPageView")
      }
    })

    /**
     * Watch blick events. If it's a button, then send
     * the appropriate event data.
     */
    document.addEventListener(
      "click",
      function (event) {
        // If the clicked element doesn't have the right selector, bail
        if (event.target.type !== "button") return

        // Log the clicked element in the console
        const url = event.target.href ? event.target.href : "unknown"
        const id = event.target.id
        const html = event.target.innerHTML
        const classList = Object.assign([], event.target.classList)

        // Send Snowplow click event
        window.pug("trackLinkClick", url, id, classList, "", html)
      },
      false
    )
  } catch (error) {
    Vue.rollbar.error('Error with Snowplow Analytics', error)
    window.pug("trackPageView")
  }
}
