// import Vue from 'vue'
// import Vlf from 'vlf'
// import localforage from 'localforage'

// Vue.use(Vlf, localforage)

// export default ({ app }, inject) => {
//   // Set the function directly on the context.app object
//   app.$vlf = Vue.$vlf
// }
import Vue from "vue"
// import Vlf from "vlf"
import localforage from "localforage"
// Vue.use(Vlf, localforage)

Vue.$vlf = localforage

Object.defineProperties(Vue.prototype, {
  $vlf: {
    get() {
      return localforage
    }
  }
})
