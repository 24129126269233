/**
 * Documentation: https://yarkovaleksei.github.io/vue2-storage/en/
 */

import Vue from "vue"
import { Vue2Storage } from "vue2-storage"

// You can pass options
Vue.use(Vue2Storage, {
  prefix: "pug_",
  driver: "local",
  ttl: 60 * 60 * 24 * 7 * 1000, // 1 week
})

export default ({ app, store }, inject) => {
  // Set the function directly on the context.app object
  app.$storage = Vue.$storage

  // app.router.afterEach(async (to, from) => {
  //   if (window && window.Appcues) {
  //     // window.Appcues.page()
  //     setTimeout(() => {
  //       let account = store.getters["accounts/getSelectedAccount"]
  //       // console.log(account, to)
  //       if (account) {
  //         window.Appcues.track(account.name)
  //       }
  //     }, 500)
  //   }
  // })
}
