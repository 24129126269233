import Vue from "vue"
import { VueMaskDirective } from "v-mask"
// import clickOutside from "@/dashboard/directives/click-outside"
import intersect from "@/dashboard/directives/intersect"
import VScrollLock from "v-scroll-lock"
import { ClickOutside } from "@/utils/helpers/click-outside"

Vue.use(VScrollLock)
// Internal Directives
Vue.directive("click-outside", ClickOutside)
// Vue.directive('intersect', intersect)

// Vendor Directives
Vue.directive("mask", VueMaskDirective)
